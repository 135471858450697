import { v4 as uuidv4 } from "uuid";

import Contact from "./Contact";

export default class Customer {
  constructor(
    id,
    company,
    contact,
    numberOfClients,
    numberOfEstablishments,
    createdAt
  ) {
    this.id = id;
    this.company = company;
    this.contact = contact;
    this.numberOfClients = numberOfClients;
    this.numberOfEstablishments = numberOfEstablishments;
    this.createdAt = createdAt;
  }

  static empty() {
    return new Customer(uuidv4(), "", Contact.empty(), 1, 0, new Date());
  }
}
