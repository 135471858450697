export const TOAST_LIFE = 3 * 1000;

export const AVAILABLE_LOCALES = [
  { language: "English", code: "en" },
  { language: "Spanish", code: "es" },
];
export const DEFAULT_LOCALE = AVAILABLE_LOCALES[0].code;

export const IS_DEBUG = process.env.NODE_ENV !== "production";
export const API_BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
export const GENERIC_CODE_ERROR = 0;
export const GENERIC_MSG_ERROR = "error.api.unknown";
export const FORBIDDEN_MSG_ERROR = "error.api.forbidden_access";
