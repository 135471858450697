import { createLogger, createStore } from "vuex";

import { container } from "../container";
import auth from "./modules/auth/auth.module";
import customer from "./modules/customer/customer.module";
import global from "./modules/global/global.module";

const plugins = container.debug ? [createLogger({})] : [];

export const store = {
  modules: {
    auth,
    customer,
    global,
  },
};

export const storeInstance = createStore({
  plugins,
  modules: store.modules,
});
