import Contact from "../models/Contact";
import Customer from "../models/Customer";
import { dateTransformer } from "./utils";

export default function (client) {
  async function register(customer) {
    await client.post("/customers", {
      id: customer.id,
      company: customer.company,
      contactName: customer.contact.name,
      contactSurname: customer.contact.surname,
      contactEmail: customer.contact.email,
    });
  }

  async function unregister(customerId) {
    await client.delete(`/customers/${customerId}`);
  }

  async function customers() {
    const { data } = await client.get("/customers");
    return data.customers.map(
      (customer) =>
        new Customer(
          customer.id,
          customer.company,
          new Contact(
            customer.contactName,
            customer.contactSurname,
            customer.contactEmail
          ),
          customer.numberOfClients,
          customer.numberOfEstablishments,
          dateTransformer.toDate(customer.createdAt)
        )
    );
  }

  return {
    register,
    unregister,
    customers,
  };
}
