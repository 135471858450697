import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./assets/layout/layout.scss";

import PrimeVue from "primevue/config";
import Ripple from "primevue/ripple";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import { createApp, reactive } from "vue";

import App from "./App.vue";
import CodeHighlight from "./AppCodeHighlight";
import { i18n } from "./i18n";
import router from "./router";
import { storeInstance } from "./store";

const app = createApp(App);

app.config.globalProperties.$appState = reactive({ inputStyle: "outlined" });
app.config.globalProperties.$primevue = reactive({ ripple: true });

app.use(i18n);
app.use(router);
app.use(storeInstance);
app.use(PrimeVue);
app.use(ToastService);

app.directive("ripple", Ripple);
app.directive("code", CodeHighlight);

app.component("Toast", Toast);

app.mount("#app");
