import { createI18n } from "vue-i18n";

import { DEFAULT_LOCALE } from "./config";
import en from "./locales/en.json";

export const i18n = createI18n({
  locale: DEFAULT_LOCALE, // set locale
  fallbackLocale: DEFAULT_LOCALE, // set fallback locale
  messages: {
    en,
  },
});
