import { FORBIDDEN_MSG_ERROR, GENERIC_CODE_ERROR } from "../config";

export const ErrorCode = {
  [GENERIC_CODE_ERROR]: "error.api.unknown",
  100: "error.api.invalid_credentials",
  101: "error.api.user_not_active",
  102: "error.api.user_already_active",
  103: "error.api.user_not_found",
  104: "error.api.user_cannot_delete_it_self",
  105: "error.api.token_expired",
  106: "error.api.token_mismatch",
  107: "error.api.token_not_found",
  108: "error.api.invalid_credentials",
  109: "error.api.token_not_found",
  1001: "error.api.accounting_not_found",
  1101: "error.api.customer_not_found",
  1102: "error.api.customer_already_exist",
  2001: FORBIDDEN_MSG_ERROR,
};
