export default class Contact {
  constructor(name, surname, email) {
    this.name = name;
    this.surname = surname;
    this.email = email;
  }

  static empty() {
    return new Contact("", "", "");
  }

  fullName() {
    return `${this.name} ${this.surname}`;
  }
}
