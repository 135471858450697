import { storeInstance } from "../store";
import { REFRESH_ACCESS_TOKEN } from "../store/modules/auth/auth.actions.type";

export async function guardPanelRouter(to, from, next) {
  try {
    await storeInstance.dispatch(REFRESH_ACCESS_TOKEN);
    next();
  } catch {
    next({ name: "login", params: to.params });
  }
}
