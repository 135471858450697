export default function (client) {
  async function activation(activate) {
    return await client.post("/auth/activation", {
      username: activate.username,
      password: activate.password,
      token: activate.token,
      legalAgreement: activate.legal,
    });
  }

  async function invitation(email) {
    return await client.post(`/auth/invitation/${email}`);
  }

  async function login(credentials) {
    const { data } = await client.post("/auth/login", {
      email: credentials.username,
      password: credentials.password,
    });
    return {
      access: data.access,
      refresh: data.refresh,
    };
  }

  async function logout() {
    return await client.post("/auth/sign-out");
  }

  async function refresh(token) {
    const { data } = await client.post("/auth/refresh-token", {
      refresh: token,
    });
    return {
      access: data.access,
      refresh: data.refresh,
    };
  }

  return {
    activation,
    invitation,
    login,
    logout,
    refresh,
  };
}
