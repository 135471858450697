<template>
  <Toast base-z-index="9999999999" />
  <router-view />
</template>
<script>
import { onErrorCaptured } from "@vue/runtime-core";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";

import { GENERIC_MSG_ERROR, TOAST_LIFE } from "./config";
import { ErrorCode } from "./models/ErrorCode";

export default {
  name: "AppRoot",
  components: {
    Toast,
  },

  setup() {
    const { t } = useI18n();
    onErrorCaptured((error) => {
      const msg = ErrorCode[error.message] ?? GENERIC_MSG_ERROR;
      const toast = useToast();
      toast.add({
        severity: "error",
        summary: t("common.error"),
        detail: t(msg),
        life: TOAST_LIFE,
      });
    });
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
