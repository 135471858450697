import jwt_decode from "jwt-decode";

import Profile from "../models/Profile";
import Token from "../models/Token";

export const parseData = {
  jwtClaims(jwtRaw) {
    try {
      return jwt_decode(jwtRaw);
    } catch (e) {
      throw new Error("Cannot parse the JWT token");
    }
  },

  profile(token) {
    return new Profile(
      token.sub,
      token.given_name,
      token.family_name,
      token.email
    );
  },

  token(jwtRaw, tokenExp) {
    return new Token(jwtRaw, new Date(tokenExp * 1000));
  },
};
